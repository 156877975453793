<template>
  <div class="company-settings" v-loading="loading">

    <div
      v-if="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
    >
      <div>
        Yandex Metrika ID:
        <el-input
          style="width: 300px"
          size="mini"
          type="number"
          :value="settings.yandex_metrika_id"
          @input="updateSettingValue('yandex_metrika_id', $event)"
          @change="saveSettingValue('yandex_metrika_id', $event)"
        >
        </el-input>
      </div>
      <div style="margin-top: 10px">
        Yandex Metrika <a target="_blank" href="https://yandex.ru/support/metrika/code/counter-initialize.html">Options</a> JSON:
        <el-input
          style="width: 300px"
          size="mini"
          type="textarea"
          :autosize="{ minRows: 1}"
          :value="settings.yandex_metrika_options"
          @input="updateSettingValue('yandex_metrika_options', $event)"
          @change="saveSettingValue('yandex_metrika_options', $event)"
        >
        </el-input>
      </div>



      <div style="margin-top: 50px">
        <import-modal
          entity="users"
          close-on-success
          @import-succeed="importSucceed"
        >
          <template v-slot:reference>
            <el-button>
              Импорт пользователей
            </el-button>
          </template>
        </import-modal>

        <import-modal
          hide-example
          entity="cards"
          close-on-success
          @import-succeed="importSucceed"
        >
          <template v-slot:reference>
            <el-button style="margin-top: 10px">
              Импорт карт
            </el-button>
          </template>
        </import-modal>
      </div>
    </div>


  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";
import {downloader} from "@/mixins/downloader";
import ImportModal from "@/components/exportImport/ImportModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "company-settings",
  components: {ImportModal},
  mixins: [companySettings],

  props: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    importSucceed(){
      this.$notify.success({
        title: 'Завершено',
        message: 'Импорт завершен успешно'
      });
    },
  }
}
</script>


<style lang="scss">

</style>